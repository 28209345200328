import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDown } from 'tabler-icons-react';
import {
  Button,
  Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row,
  UncontrolledDropdown,
} from 'reactstrap';
import Tool from '@src/types/Tool';

interface RowsPerPageProps {
  rowsPerPage: number;
  handlePerPage: (_arg1: ChangeEvent<HTMLInputElement>) => void;
  handleFilter: (_arg1: ChangeEvent<HTMLInputElement>) => void;
  showSearch?: boolean;
  tools?: Tool[];
}

const RowsPerPage = ({
  rowsPerPage, handlePerPage, handleFilter, showSearch = true, tools = [],
}: RowsPerPageProps) => {
  const { t } = useTranslation();

  return (
    <Row className="mx-0 mt-1 mb-50">
      <Col sm={(!!tools.length) && showSearch ? 4 : 6}>
        <div className="d-flex align-items-center" style={{ maxWidth: '200px' }}>
          <Label for="sort-select">{t('show')}</Label>
          <Input
            className="dataTable-select"
            type="select"
            id="sort-select"
            value={rowsPerPage}
            onChange={(e) => handlePerPage(e)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Input>
          <Label for="sort-select">{t('entries')}</Label>
        </div>
      </Col>
      {(!!tools.length) && (
        <Col sm={showSearch ? 4 : 6} className={`d-flex ${showSearch ? 'justify-content-center' : 'justify-content-sm-end'}`}>
          <UncontrolledDropdown>
            <DropdownToggle href="/" tag="a" onClick={(e) => e.preventDefault()}>
              <Button color="primary" outline style={{ paddingBottom: '8px', paddingTop: '8px' }}>
                <span style={{ display: 'flex' }}>{t('Tools')}<CaretDown style={{ marginLeft: '8px', marginTop: 'auto', marginBottom: 'auto' }} width={8} height={8} /></span>
              </Button>
            </DropdownToggle>
            <DropdownMenu end>
              {tools.map((tool) => (
                <DropdownItem className="d-block w-100" onClick={tool.onClick} key={tool.title}>
                  {tool.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      )}
      {showSearch && (
        <Col className="d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1" sm={tools.length ? 4 : 6}>
          <Label className="me-1" for="search-input">
            {t('Search')}
          </Label>
          <Input
            className="dataTable-filter"
            type="text"
            bsSize="sm"
            id="search-input"
            onChange={handleFilter}
          />
        </Col>
      )}
    </Row>
  );
};

export default RowsPerPage;
