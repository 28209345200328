import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import RequestStatus from '@src/types/RequestStatus';
import { useTranslation } from 'react-i18next';
import {
  EditorState, ContentState, convertToRaw,
} from 'draft-js';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSkin } from '@src/utility/hooks/useSkin';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { addNewFaq, editFaq, getFaqCategoriesById } from '../store';
import FaqSubmitData from '../types/FaqSubmitData';
import { faqValidationSchema } from '../validation';

const FaqForm = ({ categoryId, faqItem, setIsEditing } : {
   categoryId: string, faqItem?: FaqSubmitData,
   setIsEditing: (editing: boolean) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [editorStateRs, setEditorStateRs] = useState(() => EditorState.createEmpty());
  const [editorStateEn, setEditorStateEn] = useState(() => EditorState.createEmpty());
  const { skin } = useSkin();

  const convertHtmlToString = (editorState: EditorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    return htmlContent.toString();
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FaqSubmitData>({
    defaultValues: {
      question: faqItem?.question ?? '',
      answer: faqItem?.answer ?? '',
      questionEng: faqItem?.questionEng ?? '',
      answerEng: faqItem?.answerEng ?? '',
      categoryId: Number(categoryId),
    },
    resolver: yupResolver(
      faqValidationSchema(convertHtmlToString(editorStateRs), convertHtmlToString(editorStateEn)),
    ),
  });

  const handleSuccess = (hasItem: boolean) => {
    if (hasItem) {
      successToast(t('FAQ successfully edited'));
    } else {
      successToast(t('FAQ successfully added'));
    }

    dispatch(getFaqCategoriesById(categoryId));
    setIsEditing(false);
  };

  const handleSuccessfulSubmit = async (data: any) => {
    data.answerEng = convertHtmlToString(editorStateEn);
    data.answer = convertHtmlToString(editorStateRs);
    if (faqItem) {
      dispatch(editFaq({ id: Number(faqItem.id), data })).then((res: any) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(true);
        }
      });
    } else {
      dispatch(addNewFaq(data)).then((res : any) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          handleSuccess(false);
        }
      });
    }
  };

  useEffect(() => {
    if (faqItem) {
      const contentStateRs = htmlToDraft(faqItem.answer);
      const newEditorStateRs = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentStateRs.contentBlocks),
      );

      const contentStateEn = htmlToDraft(faqItem.answerEng);
      const newEditorStateEn = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentStateEn.contentBlocks),
      );

      setEditorStateRs(newEditorStateRs);
      setEditorStateEn(newEditorStateEn);
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={4}>
          <CustomLabel name={`${t('Question')} sr`} required />
          <Controller
            name="question"
            control={control}
            render={({ field }) => (
              <Input type="textarea" id="question" invalid={!!errors.question} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.question?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={`${t('Question')} en`} required />
          <Controller
            name="questionEng"
            control={control}
            render={({ field }) => (
              <Input type="textarea" id="questionEng" invalid={!!errors.questionEng} {...field} />
            )}
          />
          <CustomFormFeedback message={errors?.questionEng?.message} />
        </Col>
        <Col md={1} />
        <Col md={2}>
          <CustomLabel style={{ opacity: 0 }} name={t('')} />
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button
              type="submit"
              color="primary"
              disabled={false}
              outline
            >
              {faqItem ? t('Save') : t('Add')}
            </Button>
            <Button color="red" onClick={() => setIsEditing(false)}>{t('Close')}</Button>
          </Col>
        </Col>
        <Col md={4}>
          <CustomLabel name={`${t('Answer')} sr`} htmlFor="answer" required />
          <Controller
            name="answer"
            control={control}
            render={() => (
              <Editor
                wrapperStyle={{ borderRadius: '10px', border: '1px solid #3b4253' }}
                editorClassName="p-1"
                toolbarStyle={{ color: '#000', borderRadius: '10px' }}
                editorState={editorStateRs}
                onEditorStateChange={setEditorStateRs}
                toolbarClassName={skin === 'dark' ? 'toolbar-dark' : ''}
              />
            )}
          />
          <CustomFormFeedback message={errors?.answer?.message} />
        </Col>
        <Col md={4}>
          <CustomLabel name={`${t('Answer')} en`} htmlFor="answerEng" required />
          <Controller
            name="answerEng"
            control={control}
            render={() => (
              <Editor
                wrapperStyle={{ borderRadius: '10px', border: '1px solid #3b4253' }}
                editorClassName="p-1"
                toolbarStyle={{ color: '#000', borderRadius: '10px' }}
                editorState={editorStateEn}
                onEditorStateChange={setEditorStateEn}
                toolbarClassName={skin === 'dark' ? 'toolbar-dark' : ''}
              />
            )}
          />
          <CustomFormFeedback message={errors?.answerEng?.message} />
        </Col>
      </Row>
    </Form>
  );
};

export default FaqForm;
