import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { errorToast } from '@src/components/wrappers/ToastMessages';
import { EventInput } from '@fullcalendar/react';
import SelectedOptions from '@src/types/SelectedOptions';
import { Absence } from '../types/Absence';
import { AbsenceSubmitData } from '../types/AbsenceSubmitData';

export const getAllAbsences = createAsyncThunk('goKinder/getAllAbsences', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/absences${query ? '?' : ''}${query ?? ''}`,
      { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const getAbsencesByChildId = createAsyncThunk('goKinder/getAbsencesByChildId', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/absences/child/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const createAbsence = createAsyncThunk('goKinder/createAbsence', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/absences/`, { ...data }, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const deleteAbsence = createAsyncThunk('goKinder/deleteAbsence', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/absences/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const updateAbsence = createAsyncThunk('goKinder/updateAbsence', async (data: AbsenceSubmitData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/absences/${data.id}`, { ...data }, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const createMultipleAbsences = createAsyncThunk('goKinder/createMultipleAbsences', async (data: AbsenceSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/absences/multiple`, { ...data }, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err) {
    errorToast();
    return Promise.reject(err);
  }
});

export const absencesSlice = createSlice({
  name: 'absences',
  initialState: {
    allAbsences: [] as Absence[],
    calendarEvents: [] as EventInput[],
    selectedObjects: [] as SelectedOptions[],
    selectedGroups: [] as SelectedOptions[],
  },
  reducers: {
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
    setSelectedObjects(state, action) {
      state.selectedObjects = action.payload;
    },
    setSelectedGroups(state, action) {
      state.selectedGroups = action.payload;
    },
    setAllAbsences: (state, action) => {
      state.allAbsences = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAbsencesByChildId.fulfilled, (state, action) => {
      state.allAbsences = action.payload.data;
    });
    builder.addCase(getAllAbsences.fulfilled, (state, action) => {
      state.allAbsences = action.payload.data;
    });
  },
});

export const {
  setCalendarEvents,
  setSelectedObjects,
  setSelectedGroups,
  setAllAbsences,
} = absencesSlice.actions;

export default absencesSlice.reducer;
