const SMS_STATUSES_MAPPING: {
  [key: string]: string;
} = {
  'Dostavljeno na uređaj': 'Delivered',
  'Poruka poslata operateru': 'Pending',
  'Poslato - čeka proveru': 'Pending',
  'Nije moguće dostaviti': 'Failed',
  'Neuspešno slanje': 'Failed',
  'Odbijena od strane operatera': 'Failed',
  'Nepoznat status': 'Failed',
};

enum SMS_STATUSES_ENUM {
  Delivered = 'Delivered',
  Pending = 'Pending',
  Failed = 'Failed',
}

export { SMS_STATUSES_MAPPING, SMS_STATUSES_ENUM };
