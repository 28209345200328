import { useState } from 'react';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import Faq from '../types/Faq';
import FaqForm from './FaqForm';

const FaqItem = ({ faqItem, handleDelete } : {
   faqItem: Faq, handleDelete: (id: number) => void }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {isEditing ? (
        <FaqForm
          categoryId={String(faqItem.categoryId)}
          faqItem={faqItem}
          setIsEditing={setIsEditing}
        />
      ) : (
        <Row>
          <Col md={4}>
            <CustomLabel name={`${t('Question')} rs`} />
            <p>{faqItem.question}</p>
          </Col>
          <Col md={4}>
            <CustomLabel name={`${t('Question')} en`} />
            <p>{faqItem.questionEng}</p>
          </Col>
          <Col md={1} />
          <Col
            md={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Button color="primary" onClick={() => setIsEditing(true)}>{t('Edit')}</Button>
            <Button color="red" onClick={() => handleDelete(faqItem.id)}>{t('Delete')}</Button>
          </Col>
          <Col md={4}>
            <CustomLabel name={`${t('Answer')} rs`} />
            <div dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
          </Col>
          <Col md={4}>
            <CustomLabel name={`${t('Answer')} en`} />
            <div dangerouslySetInnerHTML={{ __html: faqItem.answerEng }} />
          </Col>
        </Row>
      )}
      <hr />
    </div>
  );
};

export default FaqItem;
