/* eslint-disable @typescript-eslint/ban-types */
// ** React Imports
import {
  useState, useEffect, memo, ChangeEvent,
} from 'react';

// ** Third Party Components
import { ChevronDown } from 'tabler-icons-react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

// ** Reactstrap Imports
import {
  Card, CardHeader, CardTitle, CardSubtitle, Spinner,
  Input,
} from 'reactstrap';

import '@styles/react/libs/tables/react-dataTable-component.scss';
import Tool from '@src/types/Tool';
import RowsPerPage from '@views/misc/RowsPerPage';
import PageFilters from '@src/views/misc/PageFilters';
import Filter from '@src/types/Filter';
import useBufferDownload from '@src/utility/hooks/useBufferDownload';
import FilterValue from '@src/types/FilterValue';
import Pagination from './Pagination';
import NoTableData from './NoTableData';
import SelectAllCheckbox from './SelectAllCheckbox';

interface TableProps {
  dataSource: object[];
  columns: object[];
  title: string;
  subtitle: string;
  functionButton?: JSX.Element | null;
  dataLoaded?: boolean,
  filters?: Filter[];
  onChange?: (
    name: string,
    value: FilterValue
  ) => void;
  showSearch?: boolean;
  onFilterClick?: () => void;
  query?: string,
  exportToExcel?: string;
  tools?: Tool[];
  selectedColumns?: Set<string>;
}

const DataTableWrapper = ({
  dataSource,
  columns,
  title,
  subtitle,
  functionButton,
  dataLoaded,
  filters,
  onChange,
  showSearch,
  onFilterClick,
  query = '',
  exportToExcel,
  tools = [],
  selectedColumns,
}: TableProps) => {
  // ** States
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([] as object[]);
  const { download } = useBufferDownload();
  const { t } = useTranslation();
  const [searched, setSearched] = useState(!onFilterClick);

  useEffect(() => {
    if (showSearch) {
      if (dataLoaded) {
        if (searchValue) {
          setData(dataSource?.filter(
            (n) => JSON.stringify(n).toLowerCase().includes(searchValue.toLowerCase()),
          ));
        } else {
          setData(dataSource);
        }
        setPending(false);
      }
    } else {
      setPending(!dataLoaded);
      setData(dataSource);
    }
  }, [dataLoaded, dataSource]);

  useEffect(() => {
    if ((currentPage * rowsPerPage > data?.length) && searchValue) {
      setCurrentPage(1);
    } else if ((currentPage - 1) * rowsPerPage === data?.length) {
      setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
    }
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  // ** Function to handle filter
  const handleFilter = (val: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(val.target.value);
  };

  useEffect(() => {
    setData(dataSource?.filter(
      (n) => JSON.stringify(n).toLowerCase().includes(searchValue.toLowerCase()),
    ));
  }, [searchValue]);

  // ** Function to handle Pagination and get data
  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };

  // ** Function to handle per page
  const handlePerPage = (e: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    if (currentPage * parseInt(e.target.value, 10) > data.length) {
      setCurrentPage(1);
    }
  };

  // ** Custom Pagination
  const CustomPagination = () => Pagination(
    handlePagination, currentPage, rowsPerPage, data?.length,
  );

  const handleSelectedClick = (id: string) => {
    if (selectedColumns?.has(id)) {
      selectedColumns?.delete(id);
    } else {
      selectedColumns?.add(id);
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <div className="mt-2">
            <CardTitle tag="h4">{title}</CardTitle>
            <CardSubtitle className="mt-1">{subtitle}</CardSubtitle>
          </div>
          {functionButton}
        </CardHeader>
        {onChange && filters && filters.length > 0 && (
          <PageFilters
            filters={filters}
            onChange={onChange}
            setSearched={setSearched}
            onFilterClick={onFilterClick}
          />
        )}
        {searched ? (
          <RowsPerPage
            rowsPerPage={rowsPerPage}
            handlePerPage={handlePerPage}
            handleFilter={handleFilter}
            showSearch={showSearch}
            tools={exportToExcel ? [...tools, {
              title: t('Export to excel'),
              onClick: () => {
                download({
                  downloadUrl: exportToExcel,
                  fileName: `${exportToExcel}.xlsx`,
                  downloadQuery: selectedColumns?.size ? `selectedIds=${[...selectedColumns].join(',')}` : `limit=${rowsPerPage}&page=${currentPage}&${query}`,
                });
              },
            }] : tools}
          />
        ) : <></>}
        <div className="react-dataTable">
          {searched ? (
            <DataTable
              noHeader
              pagination
              paginationServer
              className="react-dataTable"
              columns={selectedColumns ? [
                {
                  name: (
                    <SelectAllCheckbox
                      selectedColumns={selectedColumns}
                      currentPage={currentPage}
                    />
                  ),
                  minWidth: '60px',
                  maxWidth: '60px',
                  cell: (row: any) => <Input type="checkbox" className="select-column-checkbox" onClick={() => handleSelectedClick(String(row.id))} defaultChecked={selectedColumns?.has(String(row.id))} />,
                },
                ...columns,
              ] : columns}
              noDataComponent={<NoTableData searched={searched} />}
              progressPending={pending}
              progressComponent={<div />}
              sortIcon={<ChevronDown size={10} />}
              paginationComponent={CustomPagination}
              data={data?.slice(
                ((currentPage - 1) * rowsPerPage), rowsPerPage * currentPage,
              )}
            />
          ) : <></>}
          {pending && (
            <Spinner
              size="lg"
              className="mx-auto my-4 text-center d-block"
              type="border"
              color="primary"
              style={{ width: '4rem', height: '4rem' }}
            />
          )}
        </div>
      </Card>
    </>

  );
};

export default memo(DataTableWrapper);
