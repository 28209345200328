import {
  Button,
  Col, Form, Input, Row,
} from 'reactstrap';
import { useEffect } from 'react';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@store/modal';
import { getAllInvoices } from '@src/views/invoice/store';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestStatus from '@src/types/RequestStatus';
import * as yup from 'yup';
import SelectedOptions from '@src/types/SelectedOptions';
import Select from 'react-select';
import { addMultiplePayments, getPaymentStatuses, selectedPaymentStatuses } from '../store';
import PaymentState from '../types/PaymentState';

const MultiplePaymentModal = ({ invoiceIds = [] } : { invoiceIds: number[] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const paymentStore = useSelector((store: PaymentState) => store.payments);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ note: string, status: SelectedOptions, amount: number }>({
    defaultValues: {
      note: '',
      status: {
        value: 'PROKNJIŽENO',
        label: 'PROKNJIŽENO',
      },
    },
    resolver: yupResolver(
      yup.object().shape({
      }),
    ),
  });

  useEffect(() => {
    dispatch(getPaymentStatuses());
  }, []);

  useEffect(() => {
    dispatch(selectedPaymentStatuses(paymentStore.paymentStatuses.map(
      (status) => ({ label: t(`${status}`), value: status }),
    )));
  }, [paymentStore.paymentStatuses]);

  const submitHandler = async (data: {
    note: string, status: SelectedOptions, amount: number | null }) => {
    const formattedData: any = {
      status: data.status.value,
      note: data.note,
      invoiceIds,
    };

    if (data.amount) formattedData.amount = Number(data.amount);

    dispatch(addMultiplePayments(formattedData)).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        dispatch(closeModal());
        successToast(t('Payments successfully added'));
        dispatch(getAllInvoices());
      }
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={6}>
            <CustomLabel required name={t('Status')} />
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select
                  {...field}
                  options={paymentStore.selectedPaymentStatuses}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.status?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel name={t('Amount')} htmlFor="amount" />
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Input id="amount" type="number" invalid={!!errors.amount} {...field} />
              )}
            />
            <CustomFormFeedback message={errors?.amount?.message} />
          </Col>
          <Col md={12}>
            <CustomLabel name={t('Note')} htmlFor="note" />
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <Input id="note" type="textarea" invalid={!!errors.note} {...field} />
              )}
            />
            <CustomFormFeedback message={errors?.note?.message} />
          </Col>
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              type="submit"
              className="me-1"
              color="primary"
            >
              {t('Send') }
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={() => dispatch(closeModal())}
            >
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MultiplePaymentModal;
