import { AppDispatch } from '@store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionBody, AccordionHeader, AccordionItem,
  Button,
  Col,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import { Mail, Phone } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { getAllFaqCategories } from '../store';
import FaqState from '../types/FaqState';
import FaqCategories from '../types/FaqCategories';

const Faq = () => {
  const dispatch = useDispatch<AppDispatch>();
  const faqStore = useSelector((store: FaqState) => store.faq);
  const [currentCategory, setCurrentCategory] = useState<FaqCategories>();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    dispatch(getAllFaqCategories('active=1'));
  }, []);

  useEffect(() => {
    if (faqStore.allFaqCategories.length) setCurrentCategory(faqStore.allFaqCategories[0]);
  }, [faqStore.allFaqCategories]);

  const isSerbian = i18n.language === 'rs';

  return (
    <div>
      <h1 className="text-center pt-4">{t('FAQ')}</h1>
      <h4 className="text-center mt-2 mb-4">{t('Choose a category to quickly find the help you need')}</h4>
      <Row style={{ minHeight: '35vh' }}>
        <Col md={3}>
          {faqStore.allFaqCategories.map((faqCategory) => (
            <Col md={12} key={faqCategory.id}>
              <Button
                outline={faqCategory.id !== currentCategory?.id}
                color="primary"
                style={{ borderColor: '#6E6B7B 0 solid', marginBottom: '2px' }}
                block
                className="no-border"
                onClick={() => setCurrentCategory(faqCategory)}
              >
                {isSerbian ? faqCategory.name : faqCategory.nameEng}
              </Button>
            </Col>
          ))}
        </Col>
        <Col md={9}>
          <h4>{isSerbian ? currentCategory?.name : currentCategory?.nameEng}</h4>
          <p>{isSerbian ? currentCategory?.description : currentCategory?.descriptionEng}</p>
          {currentCategory
            ? (
              <UncontrolledAccordion open="">
                {currentCategory.faqs.map((faq) => (
                  <AccordionItem key={faq.id}>
                    <AccordionHeader targetId={String(faq.id)}>
                      {isSerbian ? faq.question : faq.questionEng}
                    </AccordionHeader>
                    <AccordionBody accordionId={String(faq.id)}>
                      <div
                        dangerouslySetInnerHTML={{ __html: isSerbian ? faq.answer : faq.answerEng }}
                      />
                    </AccordionBody>
                  </AccordionItem>
                ))}
              </UncontrolledAccordion>
            ) : <></>}
        </Col>
      </Row>
      <h3 className="text-center" style={{ paddingTop: '64px' }}>{t('You still have a question')}?</h3>
      <h6 className="text-center mt-2 mb-4">{t('If you can\'t find question in our FAQ, you can contact us. We\'ll answer you shortly')}!</h6>
      <Row style={{ gap: '20px' }}>
        <Col style={{ textAlign: 'end' }}>
          <Phone /> +381 60 03 01 385
        </Col>
        <Col style={{ textAlign: 'start' }}>
          <Mail /> podrska@gosoftware.rs
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
