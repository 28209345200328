import { useEffect, useState } from 'react';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { AppDispatch } from '@store/store';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'react-feather';
import useFilter from '@src/utility/hooks/useFilter';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import PackageState from '@src/views/packages/types/PackageState';
import InvoiceMassAddition from '@src/views/invoice/components/InvoiceMassAddition';
import { getAllPackages } from '@src/views/packages/store';
import ChildrenState from '../types/ChildrenState';
import Child from '../types/Child';
import {
  clearState,
  deleteChild,
  getAllChildren,
  getChildStatuses,
  setSelectedChildStatuses,
  setSelectedGroups,
  setSelectedObjects,
  setSelectedPackages,
} from '../store';
import ChildrenColumns from '../components/ChildrenColumns';
import staticFilters from '../constants/static-fitlers';

const Children: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const childrenPermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedChildren] = useState<Set<string>>(new Set<string>());
  const store = useSelector((state: ChildrenState) => state.children);
  const allChildren = useSelector(
    (state: ChildrenState) => state.children.allChildren,
  );
  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const allPackages = useSelector(
    (state: PackageState) => state.packages.allPackages,
  );

  const dispatch = useDispatch<AppDispatch>();
  const { onFilterChange, query } = useFilter();

  const handleDeleteClick = (child: Child) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: `${t('Child')} "${child.name} ${child.surname}" ${t('will be deleted.')}`,
        open: true,
        componentProps: {
          handleConfirm: async () => {
            setLoading(true);
            try {
              await dispatch(deleteChild(child.id));
            } finally {
              setLoading(false);
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const onFilterClick = () => {
    setLoading(true);
    dispatch(getAllChildren(query))
      .catch(() => { toast.error('Error while fetching objects'); })
      .finally(() => { setLoading(false); });
  };

  const handleMassAdition = () => {
    dispatch(openModal({
      Component: InvoiceMassAddition,
      title: t('Mass invoicing'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        childrenIds: [...selectedChildren],
      },
    }));
  };

  useEffect(() => {
    const urlQuery = new URLSearchParams(query);

    const selectedObjectIds = urlQuery.get('objectIds')?.split(',');
    if (selectedObjectIds) {
      const groupOptions = allTenantGroups.filter(
        (group) => selectedObjectIds?.includes(group.object.id.toString()),
      );

      dispatch(setSelectedGroups(groupOptions.map(
        (group) => ({ label: group.name, value: group.id }),
      )));
    } else {
      dispatch(setSelectedGroups([]));
      onFilterChange('groupIds', []);
    }
  }, [query]);

  useEffect(() => {
    dispatch(clearState());
    onFilterChange('childStatus', 'Aktivan');
  }, []);

  useEffect(() => {
    dispatch(setSelectedObjects(
      allTenantObjects.map((obj) => ({ label: obj.name, value: obj.id })),
    ));
    dispatch(setSelectedPackages(
      allPackages.map((pack) => ({ label: pack.name, value: pack.id })),
    ));
    dispatch(setSelectedChildStatuses(
      store.childStatuses.map((item) => ({ label: item, value: item })),
    ));
  }, [allTenantObjects, allPackages, store.childStatuses]);

  useEffect(() => {
    dispatch(getAllTenantObjects());
    dispatch(getAllTenantGroups());
    dispatch(getAllPackages());
    dispatch(getChildStatuses());
  }, []);

  return (
    <DataTableWrapper
      exportToExcel="childs-exports"
      dataSource={allChildren}
      title={t('Children')}
      subtitle={t('See, manage and filter children table')}
      columns={ChildrenColumns({ handleDeleteClick, selectedChildren })}
      dataLoaded={!loading}
      selectedColumns={selectedChildren}
      filters={[{
        label: 'Object', name: 'objectIds', options: store.selectedObjects, isMulti: true,
      },
      {
        label: 'Group', name: 'groupIds', options: store.selectedGroups, isMulti: true,
      },
      {
        label: 'Status', name: 'childStatus', options: store.selectedChildStatuses, defaultValue: store.selectedChildStatuses[0],
      },
      {
        label: 'Subvention', name: 'subvention', options: [{ label: t('Yes'), value: 1 }, { label: t('No'), value: 0 }],
      },
      {
        label: 'Package', name: 'packageIds', options: store.selectedPackages, isMulti: true,
      },
      ...staticFilters,
      ]}
      onChange={onFilterChange}
      onFilterClick={onFilterClick}
      query={query}
      showSearch={false}
      tools={[
        { title: t('Mass invoicing'), onClick: handleMassAdition },
      ]}
      functionButton={(
        isManagePermissionActive(childrenPermissions, 'Children')
          ? (
            <Button
              color="primary"
              className="text-nowrap mb-1 mt-2"
              outline
              onClick={() => { navigate('/children/new'); }}
            >
              <div className="d-flex justify-content-center">
                <Plus size={15} />
                &nbsp;
                <span>{t('Add new child')}</span>
              </div>
            </Button>
          ) : <></>
      )}
    />
  );
};

export default Children;
