// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { handleErrorMessage } from '@src/utility/Utils';
import { getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import SelectedOptions from '@src/types/SelectedOptions';
import { SelectedGroupOptions } from '@src/views/polls/types';
import SubmitData from '../types/SubmitData';
import Child from '../types/Child';
import ChildrenFile from '../types/ChildrenFile';

export const getAllChildren = createAsyncThunk('goKinder/getAllChildren', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/dashboard${query ? '?' : ''}${query ?? ''}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const deleteChild = createAsyncThunk('goKinder/deleteChild', async (childId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/childs/${childId}`, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Child information successfully deleted!']);
    return {
      data: { childId, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const addNewChild = createAsyncThunk('goKinder/addNewChild', async (data: SubmitData) => {
  try {
    const formData = new FormData();
    if (data.image) {
      formData.append('image', data.image, data.image?.name);
    }
    delete data.image;

    formData.append('subvention', data.subvention ? '1' : '0');
    delete data.subvention;

    Object.entries(data).forEach((value) => {
      if (value[1]) {
        formData.append(value[0], value[1]?.toString() ?? '');
      }
    });

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/childs`, formData, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Child information successfully added!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const editChild = createAsyncThunk('goKinder/editChild', async (data: SubmitData) => {
  try {
    const formData = new FormData();
    delete data.image;

    formData.append('subvention', data.subvention ? '1' : '0');
    data.subvention = data.subvention ? '1' : '0';

    Object.entries(data).forEach((value) => {
      if (value[1]) {
        formData.append(value[0], value[1]?.toString() ?? '');
      }
    });

    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/childs/${data.id}`, data, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Child information successfully updated!']);
    return {
      data: { ...data, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getTypesOfService = createAsyncThunk('goKinder/getTypeOfService', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/type-of-service`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getFamilyStatuses = createAsyncThunk('goKinder/getFamilyStatuses', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/family-status`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getChildStatuses = createAsyncThunk('goKinder/getChildStatuses', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/child-status`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getDocumentIdentifiers = createAsyncThunk('goKinder/getDocumentIdentifiers', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/document-identifier`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const getChildById = createAsyncThunk('goKinder/getChildById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const updateChildImage = createAsyncThunk('goKinder/updateChildImage', async ({ id, image }: { id: number, image: File }) => {
  try {
    const formData = new FormData();
    formData.append('image', image, image.name);

    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/childs/${id}/update-image`, formData, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Child\'s image successfully updated!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const deleteImage = createAsyncThunk('goKinder/deleteImage', async (childId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/childs/${childId}/remove-image`, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Child\'s image successfully deleted!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const removeParentRelationShip = createAsyncThunk('goKinder/removeParentRelationship', async ({ childId, parentId }: { childId: number, parentId: number }) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/parent-child`, {
      data: {
        childId,
        parentId,
      },
    });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(err);
  }
});

export const addParentRelationship = createAsyncThunk('goKinder/addParentRelationship', async ({ childId, parentId, contractHolderId }: { childId: number, parentId: number, contractHolderId?: number }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/parent-child`, {
      childId,
      parentId,
      contractHolderId,
    });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);

    return Promise.reject(err);
  }
});

export const getFilesByChildId = createAsyncThunk('goKinder/getFilesByChildId', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/child-files?childId=${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewFile = createAsyncThunk('goKinder/addNewFile', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/child-files/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editFile = createAsyncThunk('goKinder/editFile', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/child-files/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteFile = createAsyncThunk('goKinder/deleteFile', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/child-files/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

const initialState = {
  allChildren: [] as Child[],
  selectedObjects: [] as SelectedOptions[],
  selectedGroups: [] as SelectedGroupOptions[],
  typesOfService: [] as string[],
  selectedTypesOfService: [] as SelectedOptions[],
  familyStatuses: [] as string[],
  childStatuses: [] as string[],
  files: [] as ChildrenFile[],
  selectedFamilyStatuses: [] as SelectedOptions[],
  documentIdentifiers: [] as string[],
  selectedDocumentIdentifiers: [] as SelectedOptions[],
  selectedParents: [] as SelectedOptions[],
  selectedChildStatuses: [] as SelectedOptions[],
  currentChild: {} as Child,
  selectedPackages: [] as SelectedOptions[],
};

export const childrenSlice = createSlice({
  name: 'tenantObjects',
  initialState,
  reducers: {
    setSelectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    setSelectedGroups: (state, action) => {
      state.selectedGroups = action.payload;
    },
    setSelectedTypesOfService: (state, action) => {
      state.selectedTypesOfService = action.payload;
    },
    setSelectedFamilyStatuses: (state, action) => {
      state.selectedFamilyStatuses = action.payload;
    },
    setSelectedChildStatuses: (state, action) => {
      state.selectedChildStatuses = action.payload;
    },
    setSelectedDocumentIdentifiers: (state, action) => {
      state.selectedDocumentIdentifiers = action.payload;
    },
    setSelectedParents: (state, action) => {
      state.selectedParents = action.payload;
    },
    setSelectedPackages: (state, action) => {
      state.selectedPackages = action.payload;
    },
    clearCurrentChild: (state) => {
      state.currentChild = {} as unknown as Child;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllChildren.fulfilled, (state, action) => {
      state.allChildren = action.payload.data;
    });
    builder.addCase(deleteChild.fulfilled, (state, action) => {
      state.allChildren = state.allChildren.filter(
        (child) => child.id !== action.payload.data.childId,
      );
    });
    builder.addCase(addNewChild.fulfilled, (state, action) => {
      state.allChildren = [...state.allChildren, action.payload.data];
    });
    builder.addCase(editChild.fulfilled, (state, action) => {
      const childIndex = state.allChildren.findIndex(
        (child) => child?.id === action?.payload?.data.id,
      );
      if (childIndex >= 0) {
        state.allChildren[childIndex] = action?.payload?.data;
      }
    });
    builder.addCase(getTypesOfService.fulfilled, (state, action) => {
      state.typesOfService = action.payload.data.typeOfService;
    });
    builder.addCase(getFamilyStatuses.fulfilled, (state, action) => {
      state.familyStatuses = action.payload.data.familyStatus;
    });
    builder.addCase(getChildStatuses.fulfilled, (state, action) => {
      state.childStatuses = action.payload.data.childStatus;
    });
    builder.addCase(getDocumentIdentifiers.fulfilled, (state, action) => {
      state.documentIdentifiers = action.payload.data.documentIdentifier;
    });
    builder.addCase(getChildById.fulfilled, (state, action) => {
      state.currentChild = action.payload.data;
    });
    builder.addCase(removeParentRelationShip.fulfilled, (state, action) => {
      state.currentChild = action.payload.data;
    });
    builder.addCase(getFilesByChildId.fulfilled, (state, action) => {
      state.files = action.payload.data;
    });
  },
});

export const {
  setSelectedGroups,
  setSelectedObjects,
  setSelectedTypesOfService,
  setSelectedFamilyStatuses,
  setSelectedChildStatuses,
  setSelectedDocumentIdentifiers,
  setSelectedParents,
  setSelectedPackages,
  clearCurrentChild,
  clearState,
} = childrenSlice.actions;

export default childrenSlice.reducer;
